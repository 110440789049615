$c_primary: #00c7b9;
$c_secondary: #2593bd;
$c_btn_primary: #ffdc60;
$c_primary_hover: #206d8c;
$c_primary_text: #1d1d1d;
$c_form_border: #1d1d1d;
$c_white: #fff;
$c_black: #1d1d1d;
$c_red: #ff0000;
$c_red_hover: #c20303;
$c_text_grey: #999;
$shadow: rgba(0, 0, 0, 0.08) 0px 2px 25px 0px;
$shadow2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$transition: all 0.3s ease-in-out 0s;

// Common
.anchor_link {
  color: $c_secondary;
  transition: $transition;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    color: $c_primary_hover;
  }
}

// Button
.MuiButton-root {
  &.btn {
    height: 50px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    box-shadow: none;
    text-transform: capitalize;
    gap: 5px;

    &:hover {
      box-shadow: none;
    }

    svg {
      font-size: 20px;
    }
  }

  &.btn_primary {
    color: $c_white;
    background-color: $c_secondary;

    &:hover {
      background-color: #206d8c;
      color: $c_white;
    }
  }

  &.btn_danger {
    color: $c_white;
    background-color: $c_red;

    &:hover {
      background-color: $c_red_hover;
    }
  }

  &.sm {
    height: 35px;
    padding: 5px 10px;
  }
}

// Forms
.custom_label[class*="MuiTypography"] {
  font-size: 14px;
  font-weight: 600;
  color: $c_primary_text;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  line-height: 1.2;
}

.MuiFormControl-root,
.MuiTextField-root,
.react-tel-input {
  > .MuiInputBase {
    &-root {
      position: relative;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }

    &-hiddenLabel {
      > fieldset {
        display: none;
      }
    }
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }

  :is(input, select, input.form-control, textarea) {
    color: $c_black;
    font-weight: 500;
    height: 50px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 5px !important;
    border: 1px solid $c_form_border;
    box-shadow: none !important;

    &:focus {
      border-color: $c_secondary;
    }

    &::placeholder {
      color: $c_black;
      opacity: 0.6;
    }
  }

  :is(input, select) {
    padding: 5px 15px;
  }

  input:has(+ .eye_btn) {
    padding-right: 50px;
  }

  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.MuiFormControlLabel-root {
  color: $c_black;
  line-height: 1.2;
  margin: 0 0 0 -3px !important;
  gap: 4px;

  .MuiCheckbox-root {
    padding: 0;
    color: $c_form_border;
    position: relative;
    top: -1px;

    &.Mui-checked {
      color: $c_secondary;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 600;
    color: $c_primary_text;
    display: inline-flex;
  }
}

.form_btn {
  margin-top: 30px;
}

// Auth
.auth_module {
  min-height: 100vh;
  background: $c_secondary;
  background-attachment: fixed;

  .auth_logo {
    margin-bottom: 30px;

    .logo_cntnr {
      height: 70px;
      margin: 0;

      img {
        height: 100%;
      }
    }
  }

  .auth_cntnr {
    max-width: 480px;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
  }

  .auth_box {
    max-width: 100%;
    border-radius: 30px;
    background-color: $c_white;
    padding: 40px 40px;
    // background-image: url("../public/static/images/background.png");
    // background-repeat: no-repeat;
    // background-size:50%;
    // background-position:top;
    position: relative;

    h5 {
      font-size: 20px;
      font-weight: 800;
    }
  }

  .bg_box {
    &::after {
      content: "";
      position: absolute;
      background: url(../public/static/images/background.png);
      width: 240px;
      height: 210px;
      top: -10px;
      background-repeat: no-repeat;
      right: -31px;
      background-size: cover;
    }
  }

  .text_center {
    text-align: center;
    padding-top: 20px;
  }

  .anchor_link,
  .anchor_link p {
    font-size: 14px;
    font-weight: 600;
    display: inline-flex;
  }

  .opt_fields {
    max-width: 300px;
    margin-inline: auto;
    display: flex;
  }
}

// Sidebar
.sidebar-main-div {
  .sidebar_drawer {
    .MuiDrawer-paper {
      background-color: $c_secondary;
    }
  }
}

.sidebar-logo {
  padding: 20px 20px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: $c_secondary;
  z-index: 9;
  text-align: center;

  figure {
    width: 100%;
    height: 60px;
    margin: 0;

    img {
      max-height: 100%;
      max-width: 100%;
      cursor: pointer;
    }
  }
}

.sidebr-lst {
  padding: 0 0 20px;
  background-color: $c_secondary;

  .lst-itm {
    padding: 5px 20px;
    min-height: 55px;
    gap: 10px;
    transition: $transition;

    &:hover {
      background-color: rgba($c_primary_hover, 0.2);
      color: $c_white !important;
    }

    &:not(:last-child) {
      // margin-bottom: 5px;
    }

    .MuiListItemIcon-root {
      height: 35px;
      min-width: 35px;
      color: $c_secondary;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: $c_white;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    .lstitm-txt {
      margin: 0;
      color: $c_white;
      display: inline-flex;
      transition: $transition;

      span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.3px;
        line-height: 1.2;
        transition: $transition;
      }
    }

    &.Mui-selected {
      border-color: $c_primary_hover;
      background-color: $c_primary_hover;

      &:hover {
        background-color: $c_primary_hover !important;
        .lstitm-txt {
          // color: $c_black !important;
        }
      }
      .lstitm-txt {
        color: $c_white;

        span {
          font-weight: 600;
        }
      }
    }
  }
}

// Topbar
.top_bar {
  .topbar {
    box-shadow: $shadow;
    background-color: $c_white;

    .MuiToolbar-root {
      min-height: 70px;
      padding: 10px 20px;
      box-sizing: border-box;
    }
  }

  .topbar_user_btn {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 8px;
    border-radius: 5px;
    background-color: #f9f9f9;

    img {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      color: #555;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-left: 3px;
    }
  }

  .notification_btn,
  .chat_btn {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background-color: #f9f9f9;

    &:hover {
      color: $c_primary_hover;
    }
  }

  .chat_btn {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 10px;
      top: 10px;
      width: 7px;
      height: 7px;
      background-color: $c_secondary;
      border-radius: 50%;
      display: inline-flex;
      opacity: 0;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }
  }
}

.userDropdown_menu {
  .userDropdown_menu_list {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    min-width: 120px;

    button {
      padding: 0;
      pointer-events: none;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.notiDropdown_menu {
  .MuiMenu-paper {
    max-width: 450px;
    overflow-y: auto;
  }

  ul.MuiList-padding {
    padding: 0;
  }

  .noti_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px 15px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $c_white;
    z-index: 2;

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      color: $c_black;
    }

    .btn {
      height: 30px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .noti_list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: flex-start;
      padding: 10px 15px;
      gap: 10px;
      cursor: pointer;
      transition: $transition;

      &:hover {
        background-color: rgba(32, 78, 51, 0.1);
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      figure {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 2px;
        color: $c_black;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15px;

        span {
          font-size: 12px;
          font-weight: 500;
          margin: 0;
          color: $c_text_grey;
        }
      }

      p {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        color: #555;
      }
    }
  }
}

// Main
.dashboard_main {
  .sub-layout {
    padding: calc(70px + 25px) 20px 25px;
    max-width: 100% !important;
  }

  .cards {
    padding: 20px 20px;
    box-shadow: $shadow2;
    border-radius: 10px;
    color: $c_black;
    overflow: initial;

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;

      &_right {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }
}

.main_loyout {
  .dashboard {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    h1 {
      font-size: 20px;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 1.3;
      margin: 0;
    }
  }
}

.dashGrid {
  .dashGrid_item {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-align: left;
    gap: 20px;
    border-bottom: 2px solid #2593bd;
    cursor: pointer;
    transition: $transition;

    &:hover {
      transform: translateY(-5px);
    }

    .svg_icn {
      height: 45px;
      width: 45px;
      min-width: 45px;
      padding: 10px;
      color: $c_white;
      background: $c_secondary;
      border-radius: 5px;
      box-sizing: border-box;
    }

    h3 {
      font-size: 14px;
      color: $c_primary_text;
      font-weight: 600;
      letter-spacing: 0.3px;
      margin: 0;
      line-height: 1;
    }

    h4 {
      font-size: 25px;
      color: $c_black;
      padding-top: 10px;
      margin: 0;
      line-height: 1;
      font-family: "Montserrat", sans-serif !important;
    }
  }
}

.dashGraph {
  .dashGraph_item {
    text-align: left;

    h2 {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 1.3;
      margin: 0 0 15px;
      padding-bottom: 15px;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: $c_black;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.search_bar {
  width: 260px;
  position: relative;

  .search_icon {
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    max-height: initial;
    color: #999;
    left: 10px;
  }

  input {
    padding-left: 40px;
  }
}

.table_container {
  margin-top: 20px;

  table {
    width: 100%;

    tr {
      :is(th, td) {
        font-size: 14px;
        font-weight: 500;
        color: $c_black;
        line-height: 1.2;
        letter-spacing: 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 15px;
        white-space: nowrap;
        box-sizing: border-box;
      }

      th {
        font-weight: 700;
        background-color: rgb(32 78 51 / 20%);
        color: $c_primary_text;

        &:first-child {
          &:has(input[type="checkbox"]) {
            width: 60px;
            min-width: 60px;
          }
        }

        &:nth-child(2):contains("S.No") {
          width: 70px;
          min-width: 70px;
        }

        &:first-child,
        &:nth-child(2) {
          &:contains("S.No") {
            width: 70px;
            min-width: 70px;
          }
        }
      }
    }

    .user_img {
      margin: 0;

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
    .bnr_img {
      margin: 0;
      img {
        width: 70px;
        height: 55px;
        border-radius: 4px;
        object-fit: cover;
        object-position: center;
      }
    }

    .table_actions {
      display: inline-flex;
      align-items: center;
      gap: 5px;

      button {
        height: 35px;
        width: 35px;
        min-width: 35px;
        padding: 0;
        border-radius: 50%;
        background-color: #f5f5f5;
        color: $c_secondary;
        transition: $transition;

        svg {
          font-size: 18px;
        }

        &:hover {
          color: $c_white;
          background-color: $c_primary_hover;
        }
      }
    }

    .MuiCheckbox-root {
      padding: 0;
      color: $c_primary;
    }
  }

  .MuiFormControl-root {
    select {
      height: 35px;
      padding: 2px 20px 2px 10px !important;

      & + svg {
        font-size: 20px;
        right: 0;
      }
    }
  }
}

.upload_image {
  label {
    display: inline-flex;
  }

  &_btn[class*="btn"] {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0;
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    svg {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: $c_white;
      background-color: $c_secondary;
      padding: 6px;
      box-sizing: border-box;
      margin-top: -15px;
    }
  }

  &_preview {
    display: inline-flex;
    position: relative;
    align-items: center;
    flex-direction: column;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    svg {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: $c_white;
      background-color: $c_red;
      padding: 6px;
      box-sizing: border-box;
      margin-top: -15px;
    }
  }
}

.view_box {
  .profile_img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin: 0;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &_list {
    .MuiBox-root {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      h5 {
        color: $c_primary_text;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      p {
        color: $c_black;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .detail_title {
    font-size: 16px;
    font-weight: 700;
  }
}

.custom_tabs {
  .custom_tabs_links {
    margin-bottom: 20px;

    .MuiTabs-flexContainer {
      display: inline-flex;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px;
      gap: 5px;
      flex-wrap: wrap;

      + .MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 40px;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 600;
        color: $c_primary_text;
        text-transform: capitalize;
        border-radius: 5px;
        line-height: normal;
        transition: $transition;

        &:hover {
          color: $c_black;
          background-color: rgb(32 78 51 / 10%);
        }

        &.Mui-selected {
          color: $c_white;
          background-color: $c_secondary;
        }
      }
    }
  }

  [role="tabpanel"] {
    > .MuiBox-root {
      padding: 0;
    }
  }
}

.faq_box {
  max-width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  .faq_btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  // .btn {
  //   position: absolute;
  //   left: calc(100% + 10px);
  //   top: 0;
  // }
}

.revert_dialog {
  .MuiDialog-paper {
    max-width: 400px;
    width: 100%;

    .MuiDialogContent-root {
      padding: 30px;
    }
  }

  .dialog_title {
    font-size: 18px;
    font-weight: 700;
    color: $c_black;
    margin-bottom: 10px;
  }

  .form_btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

.react-tel-input {
  .country-list {
    padding: 0;

    .search-box {
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      line-height: normal;
      padding: 5px 10px;
      width: 100%;
      margin: 0;
      border: 1px solid $c_form_border;
    }

    li {
      &.search {
        padding: 10px 10px 5px 10px;
      }

      &.country {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 10px 8px 40px;
      }

      .flag {
        left: 10px;
        top: 4px;
        margin: 0;
      }
    }
  }
}

.chat_box {
  max-width: 1000px;
  margin: 0 auto;

  .chat_left {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      color: $c_white;
      padding: 10px 15px;
      background-color: $c_primary;
      min-height: 50px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    .chat_list {
      flex-grow: 1;
      padding: 0;
      margin: 0;
      list-style: none;
      max-height: 460px;
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.1);

      li {
        display: flex;
        align-items: flex-start;
        padding: 10px 15px;
        gap: 10px;
        cursor: pointer;
        transition: $transition;
        position: relative;

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: rgba(32, 78, 51, 0.1);
        }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        figure {
          height: 35px;
          width: 35px;
          min-width: 35px;
          border-radius: 50%;
          overflow: hidden;
          margin: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .flex {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 15px;
          margin: 0 0 2px;

          h3 {
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            color: $c_black;
            white-space: normal;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            color: $c_text_grey;
            white-space: nowrap;
          }
        }

        p {
          font-size: 12px;
          font-weight: 500;
          margin: 0;
          color: #555;
          white-space: normal;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 30px;
        }

        .MuiBadge-root {
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          right: 15px;
          bottom: 10px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $c_white;
          background-color: $c_primary;
          border-radius: 50%;
        }
      }
    }
  }

  .chat_right {
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    box-sizing: border-box;

    &_head {
      display: flex;
      align-items: center;
      min-height: 50px;
      gap: 5px;
      padding: 5px 15px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      figure {
        height: 35px;
        width: 35px;
        min-width: 35px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h2 {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .chat_body {
    padding: 15px;
    max-height: 400px;
    min-height: 400px;
    overflow: auto;
    box-sizing: border-box;
  }

  .chat_msg {
    display: flex;
    flex-direction: column;
    max-width: 70%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &_head {
      display: flex;
      align-items: center;
      gap: 10px;

      figure {
        height: 25px;
        width: 25px;
        min-width: 25px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        color: $c_text_grey;
        display: inline-flex;
      }
    }

    &_info {
      padding: 15px;
      background-color: #f5f5f5;
      border-radius: 0 15px 15px 15px;
      margin-top: 5px;

      p {
        font-size: 14px;
        font-weight: 500;
        color: $c_black;
        margin: 0;
        line-height: 1.5;
      }
    }

    &.sent {
      margin-left: auto;
      text-align: right;

      .chat_msg_head {
        justify-content: flex-end;
      }

      .chat_msg_info {
        border-radius: 15px 0 15px 15px;
      }
    }
  }

  .chat_foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    input {
      border-radius: 0;
      border: none;
      padding: 5px 15px;
      height: 60px;
      width: 100%;
    }

    button {
      height: 60px;
      width: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $c_primary;

      svg {
        font-size: 20px;
      }
    }
  }
}
.mn_hdng {
  color: $c_primary_text !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.upload_image_bnr,
.upload_image_preview2 {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 3px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}
.upload_image_preview2 {
  img {
    width: 100%;
    height: 100%;
  }
  svg {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}
.switch_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.select_div {
  height: 50px;
}
.sub_headings {
  color: $c_secondary;
  font-size: 20px !important;
  font-weight: 600 !important;
}
.Mui-disabled {
  input {
    -webkit-text-fill-color: black !important;
  }
}

.link {
  color: $c_secondary;

  &:hover {
    color: $c_primary;
  }
}
@media (max-width: 1199px) {
  main.dashboard_main.mn-lyout.MuiBox-root.css-1cepvel {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .btn_primary {
    height: 45px !important;
  }
  :is(input, select, input.form-control) {
    height: 45px !important;
  }
}

@media (max-width: 600px) {
  .upload_image_bnr,
  .upload_image_preview2 {
    width: 100%;
  }
  .custom_tabs {
    .custom_tabs_links {
      .MuiTabs-flexContainer {
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
        button {
          max-width: 100%;
        }
      }
    }
  }
}

.text_field textarea {
  color: $c_black;
  font-weight: 500;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px !important;
  border: 1px solid #eef2f6;
  box-shadow: none !important;
  background-color: $c_white;
  padding: 10px;
}
.text_field p,
.err_msg {
  color: red !important;
  font-size: 14px !important;
  margin: 0 !important;
  font-weight: 400;
  padding-top: 4px;
}
.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;
  figure {
    width: 60px;
    height: 60px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

//auth screen
.authh {
  .conta_iner {
    max-width: 1170px;
    margin: auto;
  }
  .auth_parnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 40px;
    box-sizing: border-box;
  }
  .auth_left {
    width: 44%;
    h3 {
      margin: 0;
      font-family: cursive !important;
      font-size: 25px;
      font-weight: 600 !important;
      color: $c_secondary;
      text-align: center;
    }
    h2 {
      font-size: 28px;
      text-align: center;
      margin-bottom: 0;
    }
    .google_div {
      display: flex;
      justify-content: space-between;
      width: 33%;
      margin: 20px auto;
      img {
        width: 37px;
        height: 37px;
        cursor: pointer;
        border: 1px solid $c_text_grey;
        border-radius: 100%;
      }
      img.email_fig {
        align-items: center;
        background: #f7f4f4;
        border-radius: 100px;
        display: flex;
        height: 26px;
        justify-content: center;
        width: 26px;
        padding: 6px;
        border: 1px solid $c_text_grey;
      }
    }
    h5 {
      font-size: 16px;
      text-align: center;
      color: $c_text_grey;
      font-weight: 600;
    }
  }
  .auth_ryt {
    width: 52%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .absolute_div {
      position: absolute;
      width: 70%;
      h3 {
        font-size: 26px;
        color: white;
        text-align: center;
        margin-top: 0;
      }
      p {
        font-size: 16px;
        color: white;
        text-align: center;
      }
      button {
        padding: 7px 20px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.3px;
        box-shadow: none;
        text-transform: capitalize;
        gap: 5px;
        background: $c_white;
        color: $c_black;
        width: 100px;
      }
    }
    figure {
      width: 100%;
      height: 100%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }
  }
  .dashboard2 {
    margin-bottom: 20px;
    padding: 20px;
    h1 {
      margin: 0;
      font-size: 23px;
    }
  }
}
.sub_hdng {
  h5 {
    font-size: 20px !important;
    font-weight: 800 !important;
  }
}
.mn_box {
  margin-top: 0;
  // background: $c_primary;
  // padding-right: 16px;
  // margin-left: 0px !important;
  // border-radius: 10px;
}

//upload
.upload_document.w_50 {
  label {
    .inner {
      background: #80808045;
      padding: 10px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 170px;
      cursor: pointer;
      div {
        display: none;
      }
      span {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .inner {
    height: 190px;
    position: relative;
    .cross_icn {
      svg {
        position: absolute;
        right: 6px;
        cursor: pointer;
        color: white;
        background: red;
        border-radius: 100%;
        font-size: 17px;
        top: 6px;
        padding: 1px;
        z-index: 1;
      }
    }
    figure {
      width: 100%;
      height: 100%;
      margin: 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
  }
}
.heading1 {
  font-size: 20px !important;
  text-align: center !important;
  padding-bottom: 17px !important;
  font-weight: 600 !important;
}
.notifi {
  width: 48%;
  box-sizing: border-box;
  padding: 10px;
  .flexDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #908fa13b;
    margin-left: 12px !important;
    padding-bottom: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    &:last-child {
      border-bottom: 1px solid $c_white;
    }
    p {
      margin-bottom: 0 !important;
      display: flex;
      align-items: center;
    }
  }
}
.mainCard {
  width: 100%;
  height: 310px;
  background-repeat: no-repeat;
  background: red;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  position: relative;
  figure {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 7px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .flex-eng {
    position: absolute;
    width: 100%;
    left: 10px;
    bottom: 10px;
    h3 {
      font-size: 30px;
      color: white;
      bottom: 0;
      margin: 0;
    }
    h5 {
      margin: 0;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 900;
      color: white;
      padding-bottom: 8px;
    }
  }
}
.custom_label2 {
  font-size: 28px !important;
  padding-left: 20px !important;
  padding-top: 20px !important;
  font-weight: 700 !important;
}
.filter {
  display: flex;
  width: 100%;
  justify-content: space-between;

  // align-items: center;
  .fil-ter {
    width: 24%;
    box-sizing: border-box;
    .search_bar {
      width: 100% !important;
    }
    p {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.cards_header_right2 {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100% !important;
}
//faq
.faq_sc {
  > div {
    // box-shadow: none !important;
  }
  .accordion {
    &_item {
      border: none;
      box-shadow: none;
      background-color: transparent;
      max-width: 1010px;
      margin-inline: auto;

      &::before {
        display: none;
      }

      &.Mui-expanded {
        margin-block: 0;
        margin-inline: auto;

        .accordion_title {
          &::after {
            background-image: url("../public/static/images/down.svg");
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #707070;
      }
    }

    &_title {
      padding: 20px 0;
      gap: 20px;

      &::after {
        content: "";
        width: 10px;
        height: 20px;
        background-image: url("../public/static/images/right.svg");
        background-size: cover;
        background-repeat: no-repeat;
      }

      .MuiAccordionSummary-content {
        margin: 0;
        font-size: 20px;
        font-weight: 400;
        color: $c_black;
        transition: $transition;
      }

      &:hover {
        .MuiAccordionSummary-content {
          color: $c_secondary;
        }
      }
    }

    &_content {
      padding: 0 0 25px;
    }
  }
}

.select_div {
  div {
    padding: 13px 40px 14px 20px !important;
  }
}

.login_txt {
  color: $c_secondary !important;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
}

.status_img {
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.dsabl_btn.Mui-disabled {
  background-color: hsla(243, 9%, 60%, 0.2) !important;
}

button:disabled,
input:disabled {
  background-color: hsla(243, 9%, 60%, 0.2);
  border: 1px solid transparent !important;
  cursor: not-allowed;
}
